<template>
  <v-dialog
    :value="value"
    v-if="value"
    persistent
    max-width="800"
    scrollable
  >
    <v-card>
      <v-card-title>{{ $t('addBooking') }}</v-card-title>
      <v-card-text class="py-0">
        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="locations"
                item-value="uuid"
                item-text="label"
                hide-details
                filled
                :label="$t('bookingLocation')"
                v-model="location"
                :disabled="disabled"
              />
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                :items="items"
                item-text="title"
                item-value="uuid"
                :label="$t('bookingEvent')"
                hide-details
                filled
                v-model="item"
                :disabled="disabled"
              />
            </v-col>
            <v-col
              cols="12"
              v-if="item !== null && item !== undefined"
            >
              <v-row
                dense
                align="center"
              >
                <v-col
                  cols="auto"
                  class="py-0"
                >
                  <v-btn
                    small
                    fab
                    color="error"
                    :disabled="canDecreaseQuantity"
                    @click="decreaseQuantity"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </v-col>
                <v-col class="py-0 title">
                  <v-select
                    v-model="quantity"
                    hide-details
                    solo
                    background-color="grey lighten-2"
                    :label="$t('quantity')"
                    :disabled="canSelectQuantity"
                    :items="quantityOptions"
                  />
                </v-col>
                <v-col
                  cols="auto"
                  class="py-0"
                >
                  <v-btn
                    small
                    fab
                    color="success"
                    :disabled="canIncreaseQuantity"
                    @click="increaseQuantity"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              v-if="hasQuantity"
            >
              <CustomerSelectionSimplified
                :disabled="inputDisabled"
                v-model="customer"
                filled
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              v-if="hasQuantity && customer !== null"
            >
              <DateSelection
                filled
                hide-details
                no-icon
                :label="$t('bookingDate')"
                :disabled="inputDisabled"
                v-model="bookingDate"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-if="hasQuantity && customer !== null"
            >
              <TimeSelection
                hide-details
                filled
                :label="$t('startsAt')"
                no-icon
                :disabled="inputDisabled"
                v-model="startsAtTime"
                @changeTimeValue="(e) => { this.startsAtTime = e }"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              v-if="hasQuantity && customer !== null"
            >
              <TimeSelection
                hide-details
                filled
                :label="$t('endsAt')"
                no-icon
                :disabled="inputDisabled"
                v-model="endsAtTime"
                @changeTimeValue="(e) => { this.endsAtTime = e }"
              />
            </v-col>
            <v-col
              cols="12"
              v-if="hasQuantity && customer !== null"
              class="py-0"
            >
              <v-switch
                v-model="sendTicket"
                :label="$t('sendNotificationEmailToCustomer')"
              />
            </v-col>
            <v-col v-if="location && item">
              <v-btn
                depressed
                color="primary"
                block
                large
                @click="showAvailability"
                :disabled="canSelectShowAvailability"
                :loading="isGettingAvailableTimeSlots"
              >
                <v-icon left>
                  mdi-calendar-month
                </v-icon>{{ $t('viewAvailability') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              v-if="errorMessage"
            >
              <v-alert
                border="left"
                type="error"
                class="mb-0"
              >
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container
          fluid
          class="pa-0"
        >
          <v-row justify="space-between">
            <v-col
              cols="auto"
              class="py-0"
            >
              <v-btn
                large
                block
                color="error darken-1"
                text
                @click="close"
                :disabled="disabled"
              >
                {{ $t('abort') }}
              </v-btn>
            </v-col>
            <v-col
              cols="auto"
              class="py-0"
            >
              <v-btn
                large
                block
                color="primary"
                :disabled="disabled"
                :loading="processing"
                @click="addBooking"
              >
                {{ $tc('addBooking', quantity) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import TimeSelection from "../common/TimeSelection"
  import DateSelection from "../common/DateSelection"
  import CustomerSelectionSimplified from "../customer/CustomerSelectionSimplified"

  export default {
    name: "AddBookingDialog",
    components: {
      CustomerSelectionSimplified,
      DateSelection,
      TimeSelection
    },
    props: {
      value: {
        type: Boolean
      },
      date: {
        type: String,
        default: ''
      },
      timeFrom: {
        type: String,
        default: ''
      },
      timeTo: {
        type: String,
        default: ''
      },
      bookingEvent: {
        type: String,
        default: ''
      },
      bookingTime: {
        type: Number, 
        default: 0
      },
      selectedSlot: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        processing: false,
        location: null,
        item: null,
        quantity: null,
        customer: null,
        startsAtTime: null,
        startsAtTimeInMillis: null,
        endsAtTime: null,
        endsAtTimeInMillis: null,
        bookingDate: null,
        length: null,
        errorMessage: null,
        sendTicket: true,
        booking: {
          item: null,
          quantity: 1,
          customer: null,
          startsAtTime: null,
          endsAtTime: null
        }
      }
    },
    computed: {
      hasQuantity() {
        return this.quantity !== null && this.quantity !== undefined
      },
      disabled() {
        return this.isGettingAvailableTimeSlots || this.processing
      },
      inputDisabled() {
        return this.disabled || !this.item || !this.location
      },
      primaryColor() {
        return this.$store.getters.primaryColor
      },
      primaryColorIsLight() {
        return this.$store.getters.colorIsLight(this.primaryColor)
      },
      items() {
        return this.$store.getters.bookingItems
      },
      canSelectShowAvailability() {
        return !this.item || this.disabled || !this.location
      },
      canSelectQuantity() {
        return !this.item || this.disabled
      },
      canDecreaseQuantity() {
        return !this.item || this.disabled || this.quantity <= this.quantityOptions[0]
      },
      canIncreaseQuantity() {
        return !this.item || this.disabled || this.quantity >= this.quantityOptions.slice(-1)
      },
      isGettingAvailableTimeSlots() {
        return this.$store.state.gettingAvailableTimeSlots
      },
      systemTimeInMillis() {
        return this.$store.getters.systemTime
      },
      locations() {
        return this.$store.getters.bookingLocations
      },
      quantityOptions() {
        let i = this.items.find(i => i.uuid === this.item)
        return this.$store.getters.quantityOptions(i, true)
      }
    },
    methods: {
      addBooking() {
        this.processing = true

        if(!this.item) {
          this.errorMessage = this.$t('aBookingEventNeedsToBeSelected')
          this.processing = false
          return
        }

        if(!this.customer) {
          this.errorMessage = this.$t('aCustomerNeedsToBeSelected')
          this.processing = false
          return
        }

        let itemType = this.items.find(i => i.uuid === this.item)
        if(itemType) {
          itemType = itemType.type
        }

        let newBooking = {
          item: this.item,
          customer: this.customer,
          namespace: itemType || 'event',
          sendTicket: this.sendTicket
        }

        let bookings = []

        if(this.quantity && this.quantity > 0) {
          for(let i = 0; i < this.quantity; i++) {
            let booking = JSON.parse(JSON.stringify(newBooking))
            booking.startsAtTime = Math.round(this.startsAtTimeInMillis/1000)
            booking.endsAtTime = Math.round(this.endsAtTimeInMillis/1000)
            bookings.push(booking)
          }
        }

        let bookingDate = this.bookingDate

        if(bookings && Array.isArray(bookings) && bookings.length > 0) {
          this.$store.dispatch('createMultipleBookings', { bookings, bookingDate: bookingDate}).then(results => {
            if(results && Array.isArray(results) && !results.some(r => r === undefined)) {
              this.$store.commit('updateActionSuccess', {
                message: this.$t('success'),
                subMessage: this.$t('youHaveSuccessfullyCreatedTheBooking(s)')
              })
              this.close()
            } else {
              this.errorMessage = this.$t('unableToCreateBookings')
            }
          }).catch(() => {
            this.errorMessage = this.$t('unableToCreateBookings')
          }).finally(() => {
            this.processing = false
          })
        }
      },
      close() {
        this.item = null
        this.quantity = null
        this.customer = null
        this.bookingDate = null
        this.startsAtTime = null
        this.endsAtTime = null
        this.$emit('closed')
      },
      showAvailability() {
        this.$emit('showAvailability')
      },
      availableTimeSlots() {
        return this.$store.getters.availableTimeSlots
      },
      increaseQuantity() {
        this.quantity++
      },
      decreaseQuantity() {
        this.quantity--
      },
      getAvailableTimeSlots() {
        let timeInMillis = this.systemTimeInMillis
        if(this.bookingDate) {
          timeInMillis = this.bookingDate
        }
        let start = new Date(timeInMillis * 1).setUTCHours(0,0,0,0)
        let end = new Date(timeInMillis * 1).setUTCHours(23,59,59,999)

        let payload = {
          itemUUID: this.item,
          locationUUID: this.location,
          startTimeInMillis: start,
          endTimeInMillis: end
        }

        this.$store.dispatch('getAvailableTimeSlots', payload).then(results => {
          if(results && Array.isArray(results) && results.length > 0) {
            if(this.bookingTime) {
              let t = this.bookingTime
              if(this.bookingTime.toString().length === 10) {
                t = t * 1000
              }

              let result = results.find(r => r.timestampInMillis === t)
              if(result) {
                this.startsAtTime = result.label.replace(':', '')
                this.endsAtTime = new Date(result.timestampInMillis + result.paddingInMillis+1).toISOString().substr(11,5).replace(':', '')
                return
              }
            }
            let firstTime = results[0]
            this.startsAtTime = firstTime.label.replace(':', '')
            this.endsAtTime = new Date(firstTime.timestampInMillis + firstTime.paddingInMillis+1).toISOString().substr(11,5).replace(':', '')
          }
        })
      }
    },
    mounted() {
      if(this.locations.length === 1) {
        this.location = this.locations[0].uuid
      }

      if(!this.bookingTime) {
        this.bookingDate = new Date(this.systemTimeInMillis * 1).getTime()
      }

      this.item = this.bookingEvent

      if(this.bookingTime) {
        let time = this.bookingTime
        if(time.toString().length === 10) {
          time = time * 1000
        }
        time = new Date(time)
        this.bookingDate = time.getTime()
        this.startsAtTime = time.toISOString().substr(11, 5)
      }

      if(this.selectedSlot) {
        let start = new Date(this.selectedSlot.startTimeInMillis).toISOString().substr(11,5)
        let end = new Date(this.selectedSlot.endTimeInMillis).toISOString().substr(11,5)
        this.startsAtTime = start
        this.endsAtTime = end
      }
    },
    watch: {
      customer(value) {
        if(value) {
          this.$emit('selectedCustomer', value)
        }
      },
      selectedSlot(value) {
        if(value) {
          let start = new Date(value.startTimeInMillis).toISOString().substr(11,5)
          let end = new Date(value.endTimeInMillis).toISOString().substr(11,5)
          this.startsAtTime = start
          this.endsAtTime = end
        }
      },
      item(value) {
        if(value) {
          let item = this.items.find(i => i.uuid === value)
          if(item) {
            if(item.defaultQuantity) {
              this.quantity = item.defaultQuantity
            } else {
              this.quantity = this.quantityOptions[0]
            }
          }
          this.$emit('selectedItem', value)
        }

        if(value && this.location) {
          this.getAvailableTimeSlots()
        }
      },
      location(value) {
        if(value) {
          this.$emit('selectedLocation', value)
          if(this.item) {
            this.getAvailableTimeSlots()
          }
        }
      },
      bookingDate(value) {
        if(value && this.location && this.item) {
          this.getAvailableTimeSlots()
        }
      },
      startsAtTime(value) {
        if(value) {
          let hm = []
          if(value.includes(':')) {
            hm = value.split(':')
          } else {
            hm = [value.substr(0,2), value.substr(2,2)]
          }
          this.startsAtTimeInMillis = new Date(this.bookingDate).setUTCHours(hm[0] * 1, hm[1] * 1, 0,0)
        }
      },
      endsAtTime(value) {
        if(value) {
          let hm = []
          if(value.includes(':')) {
            hm = value.split(':')
          } else {
            hm = [value.substr(0,2), value.substr(2,2)]
          }
          this.endsAtTimeInMillis = new Date(this.bookingDate).setUTCHours(hm[0] * 1, hm[1] * 1, 0,0)
        }
      },
      bookingEvent(value) {
        this.item = value
      },
      bookingTime(value) {
        if(value) {
          let time = value
          if(time.toString().length === 10) {
            time = time * 1000
          }
          time = new Date(time)
          this.bookingDate = time.getTime()
          this.startsAtTime = time.toISOString().substr(11, 5)
        }
      }
    }
  }
</script>

<style scoped>

</style>